import { Table } from 'react-bootstrap';
import './OrderTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBolt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import axiosClient from '../../settings/axiosClient';
import PopUp from '../PopUp/PopUp';
import { firstUpperCase } from '../../helpers/firstUpperCase';

const OrderTable = ({ order, btnActive, pendingOrders, setPendingOrders, setShowedOrders, products }) => {
  const [showComments, setShowComments] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [tableSpinner, setTableSpinner] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const orderTime = (Number(order.createdAt.substring(11, 13))-3) > 0 ? Number(order.createdAt.substring(11, 13))-3+order.createdAt.substring(13, 16) : Number(order.createdAt.substring(11, 13))+21+order.createdAt.substring(13, 16);
  const userAddress = `${order.orderAddress?.street ?? ""}${order.orderAddress?.number?.length !== 0 ? ` ${order.orderAddress?.number}` : ""}${order.orderAddress?.neighbour?.length !== 0 ? ` (${order.orderAddress?.neighbour})` : ""} - ${order.orderAddress?.town} - ${order.orderAddress?.province}`;

  const updateOrderStatus = async () => {
    if(spinner || tableSpinner) return;
    const newStatus = order.orderStatus === "orderplaced" ? "orderrtpu" : "orderow";
    if(newStatus === "orderrtpu"){
      setSpinner(true);
    }else if(newStatus === "orderow"){
      setTableSpinner(true);
    }
    let pendingOrdersSup = pendingOrders.concat([]);
    const orderEdited = pendingOrdersSup.find(item => item._id === order._id);
    const orderIndex = pendingOrdersSup.indexOf(orderEdited);

    const dataDB = {
      _id: order._id,
      orderStatus: newStatus, 
      client: order.ownerName,
      clientPhone: order.ownerPhone
    };

    try {
      const response = await axiosClient.post("/orders/management", dataDB);
      if (response.status === 200) {
        pendingOrdersSup[orderIndex].orderStatus = newStatus;
        const ordersUpdated = pendingOrdersSup.filter(order => order.orderStatus === 'orderplaced' || order.orderStatus === 'orderrtpu')
        if(newStatus === 'orderow'){
          setTableSpinner(true);
          setTimeout(() => {
            setPendingOrders(ordersUpdated);
            setShowedOrders(ordersUpdated);
          }, 1000)
          setSuccess(true);
          return;
        }
        setPendingOrders(ordersUpdated);
        setShowedOrders(ordersUpdated);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'})
    }
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSpinner(false);
        setSuccess(false);
        setTableSpinner(false);
      }, 1000);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setTableSpinner(false);
        setErrorPopUp(true);
      }, 1000);
    }
  }, [errors])

  return (
    <div className='m-3'>
      <Table striped bordered hover className='order-table-style position-relative'>
        {
          tableSpinner ? (
            <tbody><tr className="form-spinner"><td className='loader'></td></tr></tbody>
          ) : null
        }
        <thead>
          <tr>
            <td colSpan={3}>Pedido de: <span className='fw-bold'>{order.ownerName}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Fecha: <span className='fw-bold'>{order.createdAt.substring(0, 10)}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Hora: <span className='fw-bold'>{orderTime}</span></td>
          </tr>
        </thead>
        <tbody>
          {
            order.orderPlaced.map((order, index) => (
              <tr key={index}>
                <td>{order.quantity}{order.unit}</td>
                {/* <td>{order.productName.substring(0, 1)+order.productName.substring(1).toLowerCase()}</td> */}
                <td>{firstUpperCase(products.find(item => item._id === order.itemId)?.productName)}</td>
                <td className='price-width'>{order.pricePerUnit*order.quantity}</td>
              </tr>
            ))
          }
          <tr>
            <td colSpan={3}>Total: <span className='fw-bold'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(order.totalPrice))}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Dirección: {userAddress} {order.orderAddress?.comments?.length !== 0 && !showComments ? <span className='pointer fw-bold text-warning ms-2' onClick={() => setShowComments(true)}><FontAwesomeIcon icon={faAngleDown} /></span> : null}</td>
          </tr>
          {
            showComments ? (
              <tr>
                <td colSpan={3}>{order.orderAddress?.comments} <span className='pointer fw-bold text-warning ms-2' onClick={() => setShowComments(false)}><FontAwesomeIcon icon={faAngleUp} /></span></td>
              </tr>
            ) : null
          }
      
          {
            spinner ? (
              <>
                <tr className='position-relative'>
                  <td colSpan={3} className="form-spinner h-24 border border-top-0"><span className='loader'></span></td>
                </tr>
                <tr>
                  <td colSpan={3} >hola</td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={btnActive === "pending" ? 2 : 3}>Estado: <span className='fw-bold'>{order.orderStatus === "orderplaced" ? "Orden Recibida" : (order.orderStatus === "orderrtpu" ? "Orden Preparada" : (order.orderStatus === "orderow" ? "Orden en Camino" : (order.orderStatus === "orderreceived" ? "Orden Entregada" : null)))}</span></td>
                {
                  btnActive === 'pending' ? (
                    <td className='text-center pointer updateOrder-btn' onClick={updateOrderStatus}><FontAwesomeIcon icon={faBolt} /></td>
                  ) : null
                }
              </tr>
            )
          }
        </tbody>
      </Table>
      <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
    </div>
  );
};

export default OrderTable;