import { FLAG_MAX, FLAG_MIN, UNIT_MAX, UNIT_MIN } from "../constants";

export const updateProductData = (data) => {
  let errors = {};
  if(data.productName === '' || data.productName === null){
    errors.nombre = 'El nombre del producto no puede estar vacío.';
  } else if(data.productName.length > 50){
    errors.nombre = 'El nombre del producto no debe tener más de 50 caracteres.';
  }

  if(data.image === '' || data.image === null){
    errors.imagen = 'El nombre de la imagen no puede estar vacío.';
  } else if(data.image.length > 40){
    errors.imagen = 'El nombre de la imagen no debe tener más de 40 caracteres.';
  } else if(data.image.length < 3){
    errors.imagen = 'El nombre de la imagen no debe tener menos de 3 caracteres.';
  } else if(!(data.image.includes('.webp') || data.image.includes('.png') || data.image.includes('.gif'))){
    errors.imagen = 'El nombre de la imagen debe tener la extensión .webp o .png.';
  }

  if(!(data.unit === 'K' || data.unit === 'U')){
    errors.precio = 'Ingrese una unidad válida: U o K';
  }

  if(data.minUnit === '' || data.minUnit === 0 || typeof data.minUnit === "string" || data.minUnit === null){
    errors.min = 'Ingrese una unidad mínima válida.';
  } else if(data.minUnit < UNIT_MIN){
    errors.min = 'La unidad mínima no puede ser negativa.';
  } else if(data.minUnit > UNIT_MAX){
    errors.min = 'La unidad mínima no puede ser mayor a 30.';
  }

  if(data.delta === '' || data.delta === 0 || typeof data.delta === "string" || data.delta === null){
    errors.incremento = 'Ingrese un incremento válido.';
  } else if(data.delta < UNIT_MIN){
    errors.incremento = 'El incremento no puede ser negativo.';
  } else if(data.delta > UNIT_MAX){
    errors.incremento = 'El incremento no puede ser mayor a 30.';
  }

  if(data.flag1 === '' || data.flag1 === 0 || typeof data.flag1 === "string" || data.flag1 === null){
    errors.flag1 = 'Ingrese un valor válido.';
  }else if(data.flag1 < FLAG_MIN){
    errors.flag1 = 'El valor no puede ser menor a 1.';
  }else if(data.flag1 > FLAG_MAX){
    errors.flag1 = 'El valor no puede ser mayor a 30.';
  }else if(data.flag2 > data.flag1){
    errors.flag1 = 'El valor de la bandera 2 no puede ser mayor al valor de la bandera 1.';
  }

  if(data.flag2 === '' || data.flag2 === 0 || typeof data.flag2 === "string" || data.flag2 === null){
    errors.flag2 = 'Ingrese un valor válido.';
  }else if(data.flag2 < FLAG_MIN){
    errors.flag2 = 'El valor no puede ser menor a 1.';
  }else if(data.flag2 > FLAG_MAX){
    errors.flag2 = 'El valor no puede ser mayor a 30.';
  }

  return errors;
}
