import { Table } from 'react-bootstrap';
import './StockCompressedTable.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import checkUnitError from '../../helpers/checkUnitError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import StockCompressedRow from './StockCompressedRow';
import Spinner from '../Spinner/Spinner';
import stringSimilarity from 'string-similarity';

const StockCompressedTable = () => {
  const { stock, products } = useContext(ProductsContext);
  const [stockShowed, setStockShowed] = useState([]);
  const [sortDown, setSortDown] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [compressAll, setCompressAll] = useState(false);
  const [search, setSearch] = useState('');

  const sortData = (number) => {
    if(sortDown === 0 && number === 0) return;
    if(spinner) return;
    setSpinner(true);
    setSearch('');
    if(number === 0){
      setSortDown(0);
    }else{
      setSortDown(sortDown === 0 || sortDown === 2 ? 1 : 2);
    }
    setCompressAll(true);
  }

  const handleChange = (e) => {
    setCompressAll(true);
    setSearch(e.target.value);
    const filter = stringSimilarity.findBestMatch(e.target.value.toLowerCase(), dataArray.map(item => item.itemName.toLowerCase())).ratings.filter(item => item.rating > 0.1).sort((a, b) => b.rating - a.rating);
    let dataArraySup = [];
    filter.forEach(item => {
      dataArraySup.push(dataArray.find(data => data.itemName.toLowerCase() === item.target.toLowerCase()));
    })
    if(dataArraySup.length !== 0){
      setDataFiltered(dataArraySup);
    }else{
      setDataFiltered([]);
    }
  }

  const handleKeyUp = (e) => {
    if(e.keyCode === 27){
      setSearch('');
      setDataArray(dataArray.concat([]));
      e.target.blur();
      setCompressAll(true);
    }
  }

  useEffect(() => {
    if(products.length !== 0 && stock.length !== 0){
      let dataArray = [];
      stock.forEach(item => {
        const product = products.find(product => product._id === item.itemId);
        if(!dataArray.map(data => data.itemId).includes(item.itemId)){
          const entries = stock.filter(entry => entry.itemId === item.itemId);
          const noEmptyEntries = entries.filter(entry => entry.wholeQuantityUpdated > 0);
          dataArray.push({
            itemName: product.productName,
            quantity: entries.filter(item => item.published).reduce((a, b) => a + b.wholeQuantityUpdated, 0).toFixed(2),
            differentUnits: checkUnitError(noEmptyEntries.filter(item => item.published).map(item => item.unit)),
            actualCost: noEmptyEntries.length !== 0 ? noEmptyEntries[noEmptyEntries.length - 1].wholePrice/noEmptyEntries[noEmptyEntries.length - 1].wholeQuantity : 0,
            actualPrice: noEmptyEntries.length !== 0 ? noEmptyEntries[noEmptyEntries.length - 1].salesPricePerUnit : 0,
            itemId: item.itemId,
            unit: noEmptyEntries.length !== 0 ? noEmptyEntries[noEmptyEntries.length - 1].unit : entries[0].unit,
            flag1Warn: product.flag1Warn,
            flag2Warn: product.flag2Warn,
            lastEntryPublished: entries[0].published
          })
        }
      })
      setDataArray(dataArray);
    }
  }, [stock, products])

  useEffect(() => {
    if(dataFiltered.length !== 0){
      setStockShowed(dataFiltered);
    }else{
      setDataArray(dataArray.concat([]))
    }
  }, [dataFiltered])

  useEffect(() => {
    if(sortDown === 1){
      setStockShowed(dataArray.sort((a, b) => {
        if(a.itemName < b.itemName){
          return -1
        } else if(a.itemName > b.itemName){
          return 1
        }else {
          return 0
        }
      }).concat([]));
    }else if(sortDown === 2){
      setStockShowed(dataArray.sort((a, b) => {
        if(a.itemName > b.itemName){
          return -1
        } else if(a.itemName < b.itemName){
          return 1
        }else {
          return 0
        }
      }).concat([]));
    }else if(sortDown === 0){
      setStockShowed(dataArray.sort((a, b) => a.quantity - b.quantity).concat([]));
    }
    setSuccess(true);
  }, [dataArray, sortDown])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <div>
      {
        spinner ? <div className="form-spinner"><Spinner /></div> : null
      }
      <div className="input-group mb-3">
        <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
        <input type="text" tabIndex="-1" className="form-control" placeholder="Buscar producto por Nombre" onChange={handleChange} onKeyUp={handleKeyUp} value={search} />
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th className='text-center'>Producto <span><FontAwesomeIcon className='ms-2 pointer' onClick={sortData} icon={ sortDown === 0 || sortDown === 2 ? faArrowDown : faArrowUp } /></span></th>
            <th className='text-center'>Cantidad <span><FontAwesomeIcon className='ms-2 pointer' onClick={() => sortData(0)} icon={ faArrowDown } /></span></th>
            <th className='text-center'>Costo Venta Actual</th>
            <th className='text-center'>Precio Venta Actual</th>
            <th className='text-center'>Entrada</th>
          </tr>
        </thead>
        <tbody>
          {
            stockShowed?.map((item, index) => <StockCompressedRow key={index} item={item} stockShowed={stockShowed} compressAll={compressAll} setCompressAll={setCompressAll} />)
          }
        </tbody>
      </Table>
    </div>
  );
};

export default StockCompressedTable;