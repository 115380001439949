import { faPenSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { MAX_PROFIT, MAX_WASTE, MIN_PROFIT, MIN_WASTE, PRICE_MAX, PRICE_MIN, WHOLE_PRICE_MAX, WHOLE_PRICE_MIN, WHOLE_QUANTITY_MAX, WHOLE_QUANTITY_MIN } from '../../constants';
import stringSimilarity from 'string-similarity';
import { Button } from 'react-bootstrap';
import ProductsContext from '../../context/Products/ProductsContext';

const RowForm = ({ profit, setProfit, newStock, setNewStock, bestSuggestion, setBestSuggestion, suggestionError, setSuggestionError, stockEntrySelected, setStockEntrySelected, cancelEdition }) => {
  const [showBestSuggestion, setShowBestSuggestion] = useState(false);
  const [tabCounter, setTabCounter] = useState(0);
  const [productSelectedDB, setProductSelectedDB] = useState({});
  const [profitEffect, setProfitEffect] = useState(true);
  const [realTimeProfit, setRealTimeProfit] = useState(0);
  const { stock, products } = useContext(ProductsContext);
  

  const handleKeyUp = (e) => {
    let wholeQuantityValue = document.getElementsByName("wholeQuantity")[0].value;
    wholeQuantityValue = wholeQuantityValue === "" || wholeQuantityValue === 0 ? 1 : wholeQuantityValue;
    let wholePriceValue = document.getElementsByName("wholePrice")[0].value;
    wholePriceValue = wholePriceValue === "" ? 0 : wholePriceValue;
    let wasteValue = document.getElementsByName("waste")[0].value;
    wasteValue = wasteValue === "" ? 0 : wasteValue;
    if(e.target.name === "salesPricePerUnit"){
      setNewStock({
        ...newStock,
        salesPricePerUnit: e.target.value
      });
      const newProfit = Math.round((e.target.value / (wholePriceValue / (wholeQuantityValue - wasteValue))-1)*100);
      if(newProfit > MIN_PROFIT && newProfit < MAX_PROFIT){
        setProfit(newProfit);
        setProfitEffect(false);
      }
      return;
    }
    setProfitEffect(true);
    const salesPricePerUnitValue = (wholePriceValue !== 0 && wholeQuantityValue !== 0) ? Math.round((wholePriceValue / (wholeQuantityValue - wasteValue))*(1+profit/100)) : 0;
    setNewStock({
      ...newStock,
      [e.target.name]: e.target.value,
      salesPricePerUnit: salesPricePerUnitValue.toString() === "NaN" ? 0 : salesPricePerUnitValue
    });

    if(e.target.name === "itemName"){
      if(suggestionError || !showBestSuggestion){
        setSuggestionError(false);
        setShowBestSuggestion(true);
        setTabCounter(0);
      }
      const filter = stringSimilarity.findBestMatch(e.target.value.toLowerCase(), products.map(product => product.productName.toLowerCase())).ratings.filter(item => item.rating > 0.3).sort((a, b) => b.rating - a.rating);
      setBestSuggestion(filter.length !== 0 ? filter.map(item => item.target) : []);
    }
  }

  const tabSelection = (e) => {
    if(e.keyCode === 9 || e.keyCode === 13){
      e.preventDefault();
      if(tabCounter+1 === 2){
        setTabCounter(0);
        document.getElementsByName("wholeQuantity")[0].focus();
        return;
      }
      setTabCounter(tabCounter + 1);
      const productSelected = products.find(product => product.productName.toLowerCase() === bestSuggestion[0]);
      const stockSelected = stock.filter(stock => stock.itemId === productSelected?._id);
      if(stockSelected.length !== 0){
        setStockEntrySelected(stockSelected[0]);
      }else{
        setStockEntrySelected({});
      }
      if(productSelected){
        setProductSelectedDB(productSelected);
        setNewStock({
          ...newStock,
          itemName: bestSuggestion.length !== 0 ? bestSuggestion[0] : newStock.itemName,
          itemId: productSelected ? productSelected._id : '',
          unit: productSelected ? productSelected.unit : ''
        })
        setSuggestionError(false);
        setShowBestSuggestion(false);
      }else{
        setProductSelectedDB({});
        setShowBestSuggestion(false);
        setSuggestionError(true);
        setStockEntrySelected({});
      }
    }
  }

  const handleBlur = () => {
    setTabCounter(0);
    setShowBestSuggestion(false);
    const productSelected = products.find(product => product.productName.toLowerCase() === newStock.itemName.toLowerCase().trim());
    const stockSelected = stock.filter(stock => stock.itemId === productSelected?._id);
    if(stockSelected.length !== 0){
      setStockEntrySelected(stockSelected[0]);
    }else{
      setStockEntrySelected({});
    }
    setNewStock({
      ...newStock,
      itemName: newStock.itemName.trim()
    });
    if(!productSelected){
      setNewStock({
        ...newStock,
        itemId: ''
      })
      setSuggestionError(true);
      setProductSelectedDB({});
      setStockEntrySelected({});
    }else{
      setSuggestionError(false);
      setProductSelectedDB(productSelected);
    }
  }

  useEffect(() => {
    if(profitEffect){
      setNewStock({
        ...newStock,
        salesPricePerUnit: (newStock.wholePrice !== 0 && newStock.wholeQuantity !== 0) ? Math.round((newStock.wholePrice / (newStock.wholeQuantity - newStock.waste))*(1+profit/100)) : 0
      })
    }
  }, [profit])

  useEffect(() => {
    const possibleProfit = Math.round(Number(newStock.salesPricePerUnit/(newStock.wholePrice/(newStock.wholeQuantity-newStock.waste))-1)*10000)/100;
    if(possibleProfit.toString() !== "NaN"){
      setRealTimeProfit(possibleProfit);
    }else{
      setRealTimeProfit(0);
    }
  }, [newStock])

  useEffect(() => {
    if(Object.keys(stockEntrySelected).length !== 0){
      setNewStock({
        ...newStock,
        wholePrice: stockEntrySelected.wholePrice,
        wholeQuantity: stockEntrySelected.wholeQuantity,
        salesPricePerUnit: stockEntrySelected.salesPricePerUnit,
        waste: stockEntrySelected.waste
      })
    }else{
      setNewStock({
        ...newStock,
        wholePrice: 0,
        wholeQuantity: 0,
        salesPricePerUnit: 0,
        waste: 0
      })
    }
  }, [stockEntrySelected])

  return (
    <>
      <tr>
        <td className='position-relative pe-3'>
          <span className={newStock.onEdition ? "delete-edition" : "d-none"} onClick={cancelEdition}>x</span>
          <input autoComplete="off" readOnly={newStock.onEdition} disabled={newStock.onEdition} required className="form-control wpx" id='itemName' name="itemName" type="text" placeholder="Selecciono producto DB" value={newStock.itemName} onChange={handleKeyUp} onKeyDown={tabSelection} onBlur={handleBlur} onFocus={() => setShowBestSuggestion(true)} />
        </td>
        <td>
          <div className="d-flex justify-content-center">
            <input autoComplete="off" onFocus={(e) => e.target.select()} required className="form-control wpx-70" name="wholeQuantity" type="number" min={WHOLE_QUANTITY_MIN} max={WHOLE_QUANTITY_MAX} value={newStock.wholeQuantity} onChange={handleKeyUp} />
            <input autoComplete="off" required className="form-control wpx-40 ms-1" type="text" readOnly value={newStock.itemId !== "" ? products.find(item => item._id === newStock.itemId).unit : ""} disabled />
          </div>
        </td>
        <td className="wpx-90">
          <div className="d-flex justify-content-center">
            <input autoComplete="off" onFocus={(e) => e.target.select()} required className="form-control wpx-100" name="wholePrice" type="number" min={WHOLE_PRICE_MIN} max={WHOLE_PRICE_MAX} onChange={handleKeyUp} value={newStock.wholePrice} />
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-center">
            <input autoComplete="off" onFocus={(e) => e.target.select()} required className="form-control wpx-50" name="waste" type="number" step=".1" min={MIN_WASTE} max={MAX_WASTE} value={newStock.waste} onChange={handleKeyUp} />
            <input autoComplete="off" required className="form-control wpx-40 ms-1" type="text" readOnly value={newStock.itemId !== "" ? products.find(item => item._id === newStock.itemId).unit : ""} disabled />
          </div>
        </td>
        <td className="wpx-240">
          <div className="d-flex justify-content-center">
            <input autoComplete="off" onFocus={(e) => e.target.select()} required className="form-control wpx-80" name="salesPricePerUnit" type="number" min={PRICE_MIN} max={PRICE_MAX} value={newStock.salesPricePerUnit} onChange={handleKeyUp} onBlur={() => setProfitEffect(true)} />
          </div>
        </td>
        <td className="">
          <div className="d-flex justify-content-center">
            <input autoComplete="off" className="form-control wpx-80 text-center" type="text" value={realTimeProfit+"%"} readOnly disabled />
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-center align-items-center hpx-35">
            <Button variant={newStock.onEdition ? "outline-dark" : "outline-success"} type='submit'>{newStock.onEdition ? <FontAwesomeIcon icon={faPenSquare} /> : <FontAwesomeIcon icon={faPlus} />}</Button>
          </div>
        </td>
      </tr>
      {
        bestSuggestion.length !== 0 && showBestSuggestion ? (
          <tr><td colSpan={7} className='suggestion-row ps-1'><small className='text-muted'><span className='text-success'>{bestSuggestion[0]}</span> <span className='fst-italic'>"presione tab para elegir la sugerencia"</span><span>, {bestSuggestion.slice(1).length !== 0 ? `[${bestSuggestion.slice(1).join(', ')}]` : null}</span></small></td></tr>
        ) : null
      }
      {
        suggestionError && !showBestSuggestion && newStock.salesPricePerUnit !== 0 ? (
          <tr><td colSpan={7} className='suggestion-row ps-1'><small className='text-danger'>Por favor, seleccione un producto válido</small></td></tr>
        ) : null
      }
      {/* {
        newStock.wholeQuantity < 0 ? (
          <tr>
            <td colSpan={7} className='text-center'>
              <div className="input-group">
                <input type="text" tabIndex="-1" className="form-control border-danger" placeholder="Ingrese un motivo por salida inusual de Stock" />
                <span className="input-group-text border-danger">[caracteres]</span>
              </div>
            </td>
          </tr>
        ) : null
      } */}
    </>

  );
};

export default RowForm;