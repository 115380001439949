export const getFormattedDate = () => {
  let month = new Date().getMonth()+1;
  if(month.toString().length === 1){
    month = '0'+month.toString();
  };

  let date = new Date().getDate();
  if(date.toString().length === 1){
    date = '0'+month.toString();
  }
  return `${date}-${month}-${new Date().getFullYear()}`;
};

export const getFormattedHour = () => {
  let hour = new Date().getHours();
  if(hour.toString().length === 1){
    hour = '0'+hour.toString();
  }

  let minutes = new Date().getMinutes();
  if(minutes.toString().length === 1){
    minutes = '0'+minutes.toString();
  }
  return `${hour}:${minutes}`;
}

export const getDayData = (plus) => {
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let todayDate = new Date().getDate();
  let date = new Date().getDate();
  let dates = [];
  const defaultValidDate = 7; //Días de validez por defecto
  

  const lastDayOfTheMonth = new Date(year, month+1, 0).getDate();
  const lastDayLastMonth = new Date(year, month, 0).getDate();

  for(let i=1; i<plus; i++){
    if((date+i) > lastDayOfTheMonth && (month+2) < 12){
      const monthString = month.toString().length === 1 ? '0'+(month+2) : (month+2).toString();
      const dateString = (date+i-lastDayOfTheMonth).toString().length === 1 ? '0'+(date+i-lastDayOfTheMonth) : (date+i-lastDayOfTheMonth).toString();
      dates.push([`${year.toString()}-${monthString}-${dateString}`, i === defaultValidDate])
    }else if((date+i) > lastDayOfTheMonth && (month+2) > 12){
      const monthString = '0'+(month+2-12);
      const dateString = (date+i-lastDayOfTheMonth).toString().length === 1 ? '0'+(date+i-lastDayOfTheMonth) : (date+i-lastDayOfTheMonth).toString();
      dates.push([`${(year+1).toString()}-${monthString}-${dateString}`, i === defaultValidDate])
    }else{
      const monthString = month.toString().length === 1 ? '0'+(month+1) : (month+1).toString();
      const dateString = (date+i).toString().length === 1 ? '0'+(date+i) : (date+i).toString();
      dates.push([`${year.toString()}-${monthString}-${dateString}`, i === defaultValidDate])
    }
  }


  return dates;
};